/* contact_us section styles */
.gradient_font_color {
    color: var(--light-red-text-color);
}

#contact_section {
    margin: auto;
    width: 95.49% !important;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    padding-top: 2em;
    background: radial-gradient(46.59% 46.59% at 50% 63.22%, #27214B 0%, #170C24 100%) !important;
}

#contact_row_div {
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid;
    border-color: transparent;
    border-image: linear-gradient(to-bottom, rgb(57.79, 50.54, 100.07), rgb(20.15, 11.49, 33.14)) 1;
    background: linear-gradient(180deg, rgb(30, 22, 55) 0%, rgb(28, 18, 48) 75.17%);
}

#contact_form_div {
    padding: 64px 32px 64px 32px;
    min-height: 870px;
}

#contact_form_header {
    font: 700 3rem/120% "Poppins";
    color: var(--pure-white-color);
}

#contact_form_sub_text {
    color: var(--pure-white-color);
    font: 400 1.25rem/150% "Poppins";
    word-wrap: break-word;
    padding-top: 24px;
}

#contact_form_div .form_label {
    font: 400 1rem/170% "Poppins" !important;
    color: var(--pure-white-color);
}

.form-group {
    padding-top: 24px;
}

#form_body .form_input,
#form_body .form_input_textarea {
    border-radius: 8px;
    border: 1px solid #3D7DA0;
    background: #004870;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 12px 10px 12px 10px;
    color: var(--pure-white-color) !important;
    font: 400 1rem/27px "Poppins";
}

#form_body .form_input::placeholder,
#form_body .form_input_textarea::placeholder {
    color: #91CCED;
    font: 400 1.125rem/170% "Poppins";
    word-wrap: break-word;
}

#form_body .form_input:focus,
#form_body .form_input_textarea:focus {
    outline: none;
    border: 1px solid #3D7DA0;
    box-shadow: none !important;
}

#regarding_section .regarding_form_label {
    font: 400 1rem/24px "Poppins" !important;
    color: var(--pure-white-color);
}

.custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
    display: none;
}

.custom-checkbox .custom-checkbox-icon {
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 6px;
    border: 1px #D0D5DD solid;
    display: inline-block;
    overflow: hidden;
    background-color: transparent;
    margin: -4px !important;
}

.succes_radiant_color {
    background: linear-gradient(0deg, #9D0154 -13.28%, #EA1855 110.94%) !important;
    opacity: 1 !important;
}

.custom-checkbox input[type="checkbox"]:checked+.custom-checkbox-icon::before {
    content: '\2713';
    font-size: 18px;
    line-height: 1;
    text-align: center;
    color: #D0D5DD;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(0deg, #9D0154 -13.28%, #EA1855 110.94%) !important;
    height: 100%;
    width: 100%;
}

#message_box {
    height: 130px !important;
}

#regardin_row {
    row-gap: 12px !important;
    margin-left: -8px !important;
}

#regarding_section_label {
    padding-bottom: 16px !important;
}

#contact_form_section {
    max-width: 480px !important;
    margin: auto !important;
}

#contact_submit_btn {
    margin-top: 32px !important;
    width: 100% !important;
    border: 1px solid transparent !important;
    padding: 12px 18px 12px 18px !important;
    border-radius: 50px !important;
    opacity: 0.4;
    background: linear-gradient(180deg, #91CCED 0%, #5F889F 100%);
    Font: 400 1.5rem/36px "Poppins";
}

input:-webkit-autofill {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: var(--pure-white-color) !important;

}

.phone_input {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #3D7DA0;
    background: #004870;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px;
    margin-left: 5px;
}

#phone_number_input {
    flex-grow: 1;
}

#i-phone_number {
    color: var(--pure-white-color) !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    font: 400 0.997rem/27px "Poppins";
}

#form_body #i-phone_number::placeholder {
    color: #91CCED;
    font: 400 1.125rem/170% "Poppins";
    word-wrap: break-word;
}

#form_body #i-phone_number:focus {
    outline: none;
    border: 1px solid #3D7DA0;
    box-shadow: none !important;
}

#i-phone_num_country_code {
    font: 400 0.999rem/30px "Poppins";
    background: none !important;
    color: var(--pure-white-color) !important;
    border: none !important;
    width: 47px;
}

#i-phone_num_country_code:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

#i-phone_number:-webkit-autofill {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: var(--pure-white-color) !important;
}

#i-phone_num_country_code option {
    color: black !important;
    height: 20px !important;
    line-height: 20px !important;
}

@media screen and (max-width: 869px) {
    .regarding_checkboxes {
        width: 195px !important;
    }
}

@media screen and (min-width: 815px) and (max-width: 861px) {
    #contact_form_sub_text{
        font-size: 1.375rem !important;
    } 
}

@media screen and (min-width: 766px) and (max-width: 813.5px) {
    #contact_form_header{
        font-size: 2.75rem !important;
    }
    #contact_form_sub_text{
        font-size: 1.3rem !important;
    }
}

@media screen and (max-width:768px) {
    #map_section {
        min-height: 870px !important;
    }
}

.opacity{
    opacity: 0.4;
}
.light_blue{
    background: #476a7c !important;
}

#progess_bar_div {
    margin-top: 24px !important;
    background: linear-gradient(180deg, #91CCED 0%, #5F889F 100%);
    height: 8px !important;
    flex-shrink: 0 !important;
}

.progress-bar {
    background: #91CCED !important;
    height: 8px !important;
}

#contact_submit_success_text {
    font: 400 1.5rem/36px "Poppins";
    padding-top: 48px;
    background: linear-gradient(180deg, #91CCED 0%, #5F889F 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#contact_submit_success {
    margin-top: 48px !important;
}

@media screen and (max-width:450px) {
    #contact_form_header{
        font-size: 1.2rem !important;
    }
    #contact_form_sub_text{
        font-size: 0.85rem !important;
    }
    #contact_form_div{
        padding: 48px 24px 48px 24px !important;
    }
    #contact_form_sub_text{
        padding-top: 12px !important;
    }
}

@media screen and (max-width:430px) {
    #contact_form_div{
        padding: 64px 24px 50px 24px !important;
        min-height: initial !important;
    }
    #contact_form_header{
        font-size: 1.5rem !important;
    }
    #contact_form_sub_text {
        font-size: 1.25rem !important;
        padding-top: 34px !important;
        padding-bottom: 8px;
    }
    #contact_form_div .form_label {
        line-height: 150% !important;
        padding-bottom: 6px;
    }
    #contact_form_div .form_input::placeholder {
        font-size: 18px !important;
    }
    .phone_number_input{
        padding-left: 14px !important;
    }
    #contact_section{
        width: initial !important;
        padding-left: 32px;
        padding-right: 32px;
    }
    #map_section {
        min-height: 160px !important;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        overflow: hidden;
    }

}

