/* aurm-behind section styles */
.story_behind_button {
    width: 60px;
    height: 60px;
    padding: 18px;
    border-radius: 32px;
    gap: 6px;
    background: linear-gradient(0deg, #9D0154 10.34%, #EA1855 85.34%);
    border: none;
    color: white;
    cursor: pointer;
}

#aurm_behind_main {
    padding-top: 105px !important;
    padding-bottom: 96px !important;
}

#story_container {
    width: 86% !important;
    padding: 32px !important;
    height: 12em !important;
    background: url('../images/Container_Box.png') center no-repeat;
    background-size: cover;
    border-radius: 8px !important;
}

#story_aurm_text {
    color: var(--pure-white-color) !important;
    text-align: center;
    font: 700 48px/57.6px "Poppins";
}

#story_sub_text {
    color: rgba(95, 136, 159, 1);
    font: 400 18px/150% "Poppins";
    padding-top: 20px;
    margin-bottom: 2px !important;
}

@media screen and (max-width: 770px) {
    #story_container {
        height: 10em !important;
        padding-left: 3px !important;
        padding-right: 3px !important;
    
    }
    #story_aurm_text {
        font: 700 29px/57.6px "Poppins";
    }
    .story_behind_button {
        width: 39px;
        height: 39px;
        padding: 7px !important;
        margin-top: 10px !important;
        margin-left: 10px !important;
    }
    .aurm_behind_arrow_icon{
        height : 20px;
        width : 20px;
    }
    #story_sub_text {
        font: 400 15px/150% "Poppins";
    }
}
@media screen and (max-width: 824px) {
    #story_container {
        width: 98% !important;
    }
}

@media screen and (max-width: 430px) {
    #story_aurm_text {
        font-size: 24px!important;
        line-height: 28.8px !important;
    }
    .aurm_behind_arrow_icon{
        height : 16px;
        width : 16px;
        margin-top: -16px !important;
        margin-left: -3px !important;
    }
    .story_behind_button {
        width: 24px;
        height: 24px;
        gap: 6px;
        margin-top: 3px !important;
    }
    #aurm_behind_main {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
    #story_sub_text {
        font-size: 14px !important;
        line-height: 19.6px !important;
        padding-top: 8px !important;
    }
    #story_container {
        height: 121px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        padding-top: 32px !important;
    
    }

}


@media screen and (max-width: 385px) {
    #story_aurm_text {
        font-size: 20px!important;
        line-height: 28.8px !important;
    }
    .aurm_behind_arrow_icon{
        height : 13px;
        width : 13px;
        margin-top: -20px !important;
        margin-left: -4px !important;
    }
    .story_behind_button {
        width: 20px;
        height: 20px;
        margin-top: 5px !important;
    }
    #story_sub_text {
        font-size: 12px !important;
    }

}

