/* Blogs list styles */
#blogs_list_section {
    padding: 32px 0px 96px 0px !important;
    min-width: 360px !important;
    padding-top: 96px !important;
}

.blogs_list_primary_text {
    font: 700 3rem/57.6px "Poppins";
    letter-spacing: 0;
}

@media screen and (max-width: 431px) {
    .blogs_list_primary_text{
        font-size: 1.2rem !important;
        letter-spacing: 0;
    }
    .blogs_list_secondary_text{
        font-size: 1rem !important;
        letter-spacing: 0;
    }
    #blogs_list_section{
        padding-top: 60px !important;
    }

}


.blogs_list_blog_text_color {
    color: #DB2349;
}

.blogs_list_secondary_text {
    font: 400 1.5rem/33.6px "Poppins";
    letter-spacing: 0;
    color: #B8D5E0;
}

#blogs_list_container {
    padding: 32px 32px 32px 32px;
    border-radius: 48px !important;
    border: 2px solid transparent;
    border-color: #140B21 ;
    max-width: 1366px;
    margin-top: 96px !important;
}

.blogs_list_view_all_post_button {
    padding: 18px 18px 18px 18px;
    border-radius: 8px;
    background: linear-gradient(0deg, #9D0154 10.34%, #EA1855 85.34%);
    border: none;
    align-self: center;
    font: 600 1rem/24px "Poppins";
    letter-spacing: 0;
}

.blogs_list_view_all_post_button_home {
    padding: 12px 18px 12px 18px;
    border-radius: 60px;
    background: linear-gradient(0deg, #9D0154 10.34%, #EA1855 85.34%);
    border: none;
    align-self: center;
    font: 600 1.5rem/33.6px "Poppins";
    letter-spacing: 0;
    width: 270px;
    height: 64px;
}

.blog_title{
    margin: 144px 0px 56px 0px;
}

.blog_list_more_blogs{
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 700;
    line-height: 57.6px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--pure-white-color);
}

@media screen and (max-width: 575px) {
    .blog_list_more_blogs{
        font-family: Poppins;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 40.6px;
        letter-spacing: 0em;
        text-align: center;
        color: var(--pure-white-color);
    }

    .blogs_list_view_all_post_button_home {
        padding: 12px 18px 12px 18px;
        border-radius: 60px;
        background: linear-gradient(0deg, #9D0154 10.34%, #EA1855 85.34%);
        border: none;
        align-self: center;
        font: 600 1rem/24px "Poppins";
        letter-spacing: 0;
        width: 200px;
        height: 50px;
    }
}

@media screen and (max-width: 430px) {
    .blogs_list_primary_text, .blog_list_more_blogs{
        font: 700 1.5rem/28.8px "Poppins" !important;
    }
    .blogs_list_secondary_text {
        font: 400 1rem/22.4px "Poppins" !important;
        padding: 0 2.5rem !important;
    }
    #blogs_list_container {
        padding: 32px 32px 32px 32px;
        border-radius: 48px !important;
        border-color:#140B21 !important;
        border: 2px solid transparent;
        margin-top: 42px !important;
    }
    .blogs_list_view_all_post_button_home {
        border-radius: 2.3475rem !important;
        padding: 7.51px 11.27px 7.51px 11.27px !important;
        border: none;
        align-self: center;
        font: 600 0.95rem/21.03px "Poppins";
        letter-spacing: 0;
        width: 169px !important;
        height: 40px !important;
    }
    .blog_title{
        margin: 64px 0px 0px 0px;
        font-size: 24px !important;
    }
    .blogs_list_view_all_post_button {
        padding: 7.51px 11.27px 7.51px 11.27px !important;
        font: 600 0.95rem/21.03px "Poppins";
        width: 149px !important;
        height: 45px !important;
    }
    .blog_post_title{
        height: auto !important;
        overflow: visible !important;
        font-size: 1.15em !important;
    }
}



