#comitmet_standard_section {
    background: radial-gradient(37.98% 37.98% at 50% 54.62%, #27214b 0, #170c24 100%);
    padding: 120px 0 !important;
    padding-bottom: 66px !important;
}

#comitmet_header {
    font: 700 3rem/120% "Poppins";
    color: var(--pure-white-color);
}

#comitment_header_sub_text {
    color: var(--light-red-text-color);
}

.standard_images_container {
    padding-top: 56px;
}

.standard_image{
    height: 150px;
}

.standard_text {
    font: 700 28px/39.2px "Poppins";
}

.standard_img_div {
    max-width: 17.8em;
}

@media screen and (max-width: 1199.5px) {
    #comitmet_header {
        font-size: 2rem;
    }

    .standard_iso_row {
        row-gap: 1.5em;
        column-gap: 1.5em;
    }

    .standard_text {
        font-size: 24px;
    }
}

@media screen and (max-width: 570px) {
    #comitmet_header {
        font-size: 1.8rem;
    }

    .standard_text {
        font-size: 24px;
    }
}

@media screen and (max-width: 430px) {
    #comitmet_header {
        font-size: 24px;
    }

    .standard_text {
        font-size: 20px;
        line-height: 35.2px;
    }
    .standard_img_div {
        max-width: 15em;
        min-width: 7em;
    }
    #comitmet_standard_section {
        padding-top: 76px !important;
        padding-bottom: 36px !important;
    }
    #comitmet_standard_section {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
    .standard_images_container{
        padding-left: 0px;
        padding-right: 0px;
    }
}