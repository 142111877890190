#individual_blogs_page_div{
    min-width: 360px;
}

.banner_height{
    max-height: 480px;
    object-fit: cover;
    width: -webkit-fill-available;
    width: -moz-available;
}

#blogs_page_div {
    background: radial-gradient(59.12% 59.12% at 50% 50%, #27214B 0%, #170C24 100%) !important;
}


#blogs_page_div #blogs_list_section {
    padding-bottom: 0px !important;
    background: none !important;
}

#blogs_page_div #blogs_list_section #blogs_list_container {
    margin-top: 56px !important;
}

#individual_blog_main_text {
    font: 400 1.125rem/27px "Poppins";
    letter-spacing: 0em;
    color: var(--pure-white-color);
    opacity: 0.6;
}

#individual_blog_published_text{
    font: 600 1rem/24px "Poppins";
    letter-spacing: 0em;
    text-align: center;
    color: #CF1E4D;
    padding: 96px 0px 0px 0px;
}

#individual_blog_locker_text{
    font: 700 3rem/57.6px "Poppins";
    letter-spacing: 0em;
    text-align: center;
    color: var(--pure-white-color);
}

.individual-blog-custom-box{
    display: inline-block;
}

.padding_between_image_text-box{
    padding-left: 10px !important;
}

.individual-blog-design-box{
    width: 67px;
    height: 24px;
    padding: 2px 10px 2px 10px;
    border-radius: 1000px;
    border: 1px solid #000;
    background: linear-gradient(0deg, #E9D7FE, #E9D7FE),
                linear-gradient(0deg, #F9F5FF, #F9F5FF);
    margin-right: 10px;
}

.individual-blog-research-box{
    width: 83px;
    height: 24px;
    padding: 2px, 10px, 2px, 10px;
    border-radius: 1000px;
    background: linear-gradient(0deg, #C7D7FE, #C7D7FE),
                linear-gradient(0deg, #EEF4FF, #EEF4FF);
    border: 1px solid #C7D7FE;
    margin-right: 10px;

}
.individual-blog-presentation-box{
    width: 106px;
    height: 24px;
    padding: 2px, 10px, 2px, 10px;
    border-radius: 1000px;
    border: 1px;
    background: linear-gradient(0deg, #FCCEEE, #FCCEEE),
                linear-gradient(0deg, #FDF2FA, #FDF2FA);
    border: 1px solid #FCCEEE;
}

#individual_blog_tag{
    text-align: center;
    margin-top: 20px;
    align-items: center;
}



.individual-blog-design-text{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #6941C6;
}

.individual-blog-research-text{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #3538CD;
}

.individual-blog-presentation-text{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #C11574;
}

.individual-blog-tag-div{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.indvidual_blog_blocg_list{
    margin-bottom: 2rem !important;
}

.individual_blog_paragraph{
    margin-top: 2rem;
}
.individual_blog_content{
    padding-bottom: 6rem;
}

@media screen and (max-width: 575px) {
    .individual_blog_content {
        width: 88% !important;
        margin-left: auto;
        margin-right: auto;
    }

    #individual_blog_main_text {
        font: 350 1rem/27px "Poppins";
        letter-spacing: 0em;
        color: var(--pure-white-color);
    }
    #individual_blog_locker_text{
        font: 600 2.5rem/57.6px "Poppins";
        letter-spacing: 0em;
        text-align: center;
        color: var(--pure-white-color);
    }
}

@media screen and (max-width: 430px) {
    #individual_blog_locker_text {
        font: 600 1.25rem/28px "Poppins";
    }
    .individual_blogs_container{
        padding: 0px !important;
    }
    .individual_blogs_banner{
        margin-top: 4.2rem !important
    }
    #individual_blog_main_text{
        font-size: 14px !important;
        line-height: 27px;
        font-weight: 500;
    }
}
