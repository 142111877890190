#our_story_page_div {
    background: radial-gradient(59.12% 59.12% at 50% 50%, #27214B 0%, #170C24 100%) !important;
}

#our_story_container {
    padding: 176px 0px 96px 0px !important;
}

#our_story_container #strory_main_head {
    font: 700 3rem/57.6px "Poppins";
    letter-spacing: 0em;
    text-align: center;
    color: var(--pure-white-color);
}

#our_story_container #story_sub_head {
    font: 500 1.5rem/30px "Poppins";
    text-align: center;
    color: #B8D5E0;
    margin-top: 12px;
    margin-bottom: 12px;
}

#our_story_container #story_sub_head_2 {
    font: 400 1.1rem/30px "Poppins";
    text-align: center;
    color: #B8D5E0;
}

@media screen and (max-width: 560px) {
    #story_header_container {
        padding-left: 32px !important;
        padding-right: 32px !important;
    }

    #our_story_container #strory_main_head {
        font-size: 30px !important;
    }

    #our_story_container #story_sub_head {
        font-size: 18px !important;
    }

    #our_story_container #story_sub_head_2 {
        font-size: 16px !important;
    }

    #our_story_container {
        padding: 96px 0px 36px 0px !important;
    }
}

.gallery_btn_div {
    display: flex;
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #004870;
    height: 90px;
    justify-content: center;
    align-items: center;
}

.gallery_btn_font_style {
    font: 600 1.75rem/42px "Poppins";
    color: var(--pure-white-color);
}

.margin-top-class {
    margin-top: 1.5rem !important;
}

.btn-margin-top{
    margin-top: 1.5rem !important;
}

.row-margin-top{
    margin-top: 1.5rem !important;
}

@media screen and (max-width: 768px) {
    .padding_start_none{
        padding-left: 0 !important;
    }
    .padding_end_none{
        padding-right: 0 !important;
    }
    .padding_x_none{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .margin-top-class {
        margin-top: .75rem !important;
    }
    
    .btn-margin-top{
        margin-top: .75rem !important;
    }
    
    .row-margin-top{
        margin-top: .75rem !important;
    }
    #our_story_container {
        padding: 96px 0px 36px 0px !important;
    }
}

.build_header {
    color: #B8D5E0;
    font: 600 2rem/48px "Poppins";
    padding-bottom: 40px;
}

.build_sub_head {
    color: #B8D5E0;
    font: 400 1.125rem/32px "Poppins";
    line-height: normal !important;
}

.dot_list {
    margin-top: -5px !important;
}

#sub_head_text {
    padding-bottom: 24px;
}

#bulid_img_one {
    border-radius: var(--radius-2xl, 16px);
    background-position: center;
    background-repeat: no-repeat;
}

#building_img {
    width: 496px !important;
    height: auto !important;
}

.afilated_row {
    gap: 32px !important;
}

.afilated_text_div {
    width: 54% !important;
}

@media screen and (max-width: 991.5px) {
    .build_header {
        padding-bottom: 15px !important;
        font-size: 1.8rem;
    }

    #sub_head_text {
        padding-bottom: 10px !important;
    }

    .afilated_row {
        margin: auto !important;
    }

    .afilated_text_div {
        width: 53% !important;
    }
}


@media screen and (max-width: 540.5px) {
    #building_img {
        width: 100% !important;
        height: 100% !important;
    }

    .afilated_text_div {
        width: 100% !important;
    }
}

@media screen and (max-width: 767.5px) {
    #building_img {
        width: 100% !important;
        height: 100% !important;
    }

    .afilated_text_div {
        width: 100% !important;
    }
}

@media (min-width: 1399px) {
    #lady_img {
        height: 448px !important;
    }

    .laser_stairs_img {
        height: 619px !important;
    }
}

@media (max-width: 1399px) {
    #lady_img {
        height: 371px !important;
    }

    .laser_stairs_img {
        height: 533px !important;
    }
}

@media (min-width: 991px) and (max-width: 1199px) {
    #lady_img {
        height: 292px !important;
    }

    .laser_stairs_img {
        height: 451px !important;
    }
}

@media (max-width: 991px) {
    #lady_img {
        height: 185px !important;
    }

    .laser_stairs_img {
        height: 339px !important;
    }
}

@media (max-width: 767px) {
    #lady_img {
        height: 142px !important;
    }

    .laser_stairs_img {
        height: 252px !important;
    }

    .gallery_btn_font_style {
        font-size: 1.2rem !important;
    }

    .gallery_btn_div {
        height: 70px !important;
        border-radius: 12px;
    }
}

@media (max-width: 575px) {
    .padding-class {
        padding-right: 0 !important;
    }

    .padding-left-class {
        padding-left: 0 !important;
    }

    #lady_img {
        height: 144px !important;
    }

    .laser_stairs_img {
        height: 216px !important;
    }

    .story_gallery_section_row {
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
    }

    .gallery_btn_font_style {
        font-family: Poppins;
        font-size: 0.625em !important;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .gallery_btn_div {
        height: 60px !important;
        border-radius: 10px;

    }
}

@media (max-width: 520px) {
    .padding-class {
        padding-right: 0 !important;
    }

    .padding-left-class {
        padding-left: 0 !important;
    }

    #lady_img {
        height: 126px !important;
    }

    .laser_stairs_img {
        height: 187px !important;
    }

    .story_gallery_section_row {
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
    }

    .gallery_btn_div {
        height: 55px !important;
        border-radius: 9px;

    }
}

@media (max-width: 465px) {
    .padding-class {
        padding-right: 0 !important;
    }

    .padding-left-class {
        padding-left: 0 !important;
    }

    #lady_img {
        height: 108px !important;
    }

    .laser_stairs_img {
        height: 154px !important;
    }

    .story_gallery_section_row {
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
    }

    .gallery_btn_div {
        height: 45px !important;
        border-radius: 7px;

    }
}

@media (max-width: 410px) {
    .padding-class {
        padding-right: 0 !important;
    }

    .padding-left-class {
        padding-left: 0 !important;
    }

    #lady_img {
        height: 70px !important;
    }

    .laser_stairs_img {
        height: 143px !important;
    }

    .story_gallery_section_row {
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
    }

    .gallery_btn_div {
        height: 40px !important;
        border-radius: 6px;

    }
}

@media (max-width: 380px) {
    #lady_img {
        height: 60px !important;
    }

}

@media (max-width: 367px) {

    .padding-class {
        padding-right: 0 !important;
    }

    .padding-left-class {
        padding-left: 0 !important;
    }

    .laser_stairs_img {
        height: 143px !important;
    }

    .story_gallery_section_row {
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
    }

    .gallery_btn_div {
        height: 40px !important;
        border-radius: 6px;
    }
}

@media (max-width: 360px) {


    .padding-class {
        padding-right: 0 !important;
    }

    .padding-left-class {
        padding-left: 0 !important;
    }

    .laser_stairs_img {
        height: 143px !important;
    }

    .story_gallery_section_row {
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
    }

    .gallery_btn_div {
        height: 40px !important;
        border-radius: 6px;

    }
}

/* bannerstyles */

.overlay-div {
    border-radius: var(--radius-2xl, 16px);
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    width: 90%;
    margin: auto !important;
    margin-bottom: 2em !important;
}

#aurm_banner_header_text {
    font: 600 2.5rem/60px "Poppins";
    color: var(--pure-white-color);
    padding-top: 1.5rem;
}

.frame_svg_text {
    color: #DFDFDF;
    font: 500 1.125rem/20px "Poppins";
}

.frame_div {
    gap: 1rem !important;
    align-items: center;
}

.main_svg_div {
    display: flex;
    gap: 48px;
    justify-content: center !important;
    padding-bottom: 32px;
    width: 90%;
    margin: auto;
}

#aurm_banner_content {
    color: #B8D5E0;
    font: 500 1.5rem/140% "Poppins";
}

#aurm_banner_sub_content {
    color: #B8D5E0;
    font: 400 1.25rem/140% "Poppins";
}

@media screen and (max-width: 1199px) {
    .frame_svg_text {
        font-size: 0.8rem !important;
    }

    #aurm_banner_header_text {
        font-size: 2rem !important;
    }
}

@media screen and (max-width: 991px) {
    .frame_svg_text {
        font-size: 0.7rem !important;
    }

    .main_svg_div {
        gap: 19px;
        width: 95%;
        margin-bottom: 1em !important;
        padding-bottom: 1em !important;

    }

    .overlay-div {
        margin-bottom: 1em !important;
    }

}

@media screen and (max-width: 767px) {
    .frame_svg_text {
        font-size: 0.6rem !important;
        line-height: 12px !important;
    }

    .main_svg_div {
        gap: 19px;
        width: 95%;
        margin-bottom: 0.2em !important;
        padding-bottom: 0.1em !important;

    }

    .overlay-div {
        margin-bottom: 0.5em !important;
    }

    #aurm_banner_header_text {
        padding-top: 1em !important;
        font-size: 1.5rem !important;
        line-height: 20px !important;
    }

}

@media screen and (max-width: 520px) {
    #aurm_banner_section_desktop {
        display: none !important;
    }

    #aurm_banner_section_mobile {
        display: block !important;
    }

    #aurm_banner_content {
        color: #B8D5E0;
        font-size: 20px !important;
    }

    #aurm_banner_sub_content {
        font-size: 16px !important;
    }

    .frame_div {
        width: 11em;
    }
}

#affilate_section3 .build_header {
    font-size: 40px !important;
}

.build_header_2 {
    color: #B8D5E0 !important;
    font: 500 1.55rem/140% "Poppins";
    padding-bottom: 40px;
}


#how_it_works_video_section .eligibility_btn_div {
    display: none !important;
}

#how_it_works_video_section #watch_video_bottom_img_div {
    margin-top: -3.1em !important;
}

@media screen and (max-width: 550px) {
    #how_it_works_video_section #watch_video_bottom_img_div {
        margin-top: -2.34em !important;
    }
}

#first_vid_scn .eligibility_btn_div {
    display: none !important;
}

#first_vid_scn #watch_video_text {
    display: none !important;
}

#first_vid_scn #video_top_layer {
    display: none !important;
}

#first_vid_scn .video_poster_div {
    border-radius: var(--radius-2xl, 16px);
    overflow: auto !important;
}

#first_vid_scn #watch_video_section{
    padding-left: 0px !important;
    padding-right: 0px !important;
}


#first_vid_scn #watch_video_bottom_img_div {
    margin-top: -3.1em !important;
}

@media screen and (max-width: 550px) {
    #first_vid_scn #watch_video_bottom_img_div {
        margin-top: -2.34em !important;
    }
}

#story_footer #footer_section {
    margin-top: 5rem !important;
}


#aurm_banner_mobile_header_text {
    font: 600 12px/30px "Poppins";
    color: var(--pure-white-color);
    padding-bottom: 0px !important;
    margin-top: 3px !important;
}

.mobile_svg_text {
    font-size: 10px !important;
}

.main_svg_div_mobile {
    display: flex;
    gap: 33px;
    width: 90%;
    margin: auto;
    justify-content: flex-start !important;
    margin-bottom: 0.2em !important;
    padding-bottom: 8px !important;
}

.float-right {
    float: right !important;
}

.aurm_img_banner{
    width: 100% !important;
}

@media (1200px > width ){
    .modal-xl{
        max-width: 100% !important;
        margin: 0 2em !important;
    }
}

#video_close_button{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: end;
}

.margin_top_gallery{
    margin-top: 24px !important;
}

#lady_img {
    flex-grow: 1 !important;
}
.laser_stairs_img{
    flex-grow: 1 !important;
}

@media screen and (max-width: 430px) {
    .gallery_btn_font_style{
        font-size: 0.622rem !important;
    }
    #our_story_section_container {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
    #story_header_container{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    #our_story_container #story_sub_head_2 {
        font-size: 14px !important;
    }
    .affilate_section1{
        padding-top: 0px !important;
        margin-top: 36px !important;
    }
    .affilate_section2, #affilate_section3{
        padding-top: 0px !important;
        margin-top: 64px !important;
    }
    
    #aurm_banner_section_mobile{
        padding-top: 0px !important;
        margin-top: 36px !important;
    }
    #endover_text_div{
        padding-top: 0px !important;
        margin-top: 36px !important;
    }
    .build_header{
        font-size: 16px !important;
        line-height: 140% !important;
    }
    .afilated_row {
        gap: 23px !important;
    }
    .build_sub_head{
        font-size: 14px !important;
    }
    #sub_head_text{
        padding-bottom: 24px !important;
    }
    #aurm_banner_content {
        font-size: 16px !important;
    }
    #aurm_banner_sub_content {
        font-size: 14px !important;
    }
    #story_gallery_section_2{
        padding-top: 0px !important;
        margin-top: 64px !important;
    }

    #affilate_section3 .build_header {
        font-size: 24px !important;
    }
    .build_header_2{
        font-size: 16px !important;
        line-height: 140%;
        padding-bottom: 16px !important;
    }
    #how_it_works_video_section {
        margin-top: initial !important;
    }
    #first_vid_scn{
        margin-top: initial !important;
    } 

}
