/* Header section styles */
#header_section .custom_toggler_icon {
    width: 1.875rem;
    height: 0.188rem;
    background-color: var(--pure-white-color);
    margin: 0.375rem auto;
}

#header_container {
    z-index: 1000;
    position: absolute;
    left: 0 !important;
    width: 100%;
    height: 80px;
    border: 0px 0px 1px 0px;
    padding-left: 32px;
}

#header_section .nav_container_div {
    width: 88.8%;
    min-height: 44px;
    padding: 16px 0px !important;
}

#header_section .aurm_logo {
    width: 99.51px;
    height: 36px;
}

#header_section .nav_action_buttons {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    margin-right: 24px;
}

#header_section .nav_action_buttons:hover {
    cursor: pointer;
}

#header_section .button_spacing {
    padding: 0px 2px 0px 2px;
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
}

#header_container.fixed {
    position: fixed !important;
    top: 0;
    background: #170C24;
}

#header_section .nav_options {
    color: var(--pure-white-color);
    font: 500 16px/24px "Poppins";
    position: relative;
}

#header_section .head_footer_link_text{
    background: var(--Text-Colour---Gradient, linear-gradient(180deg, #91CCED 0%, #5F889F 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none !important;
}

#header_section .head_footer_item {
    padding: 10px 13px 10px 0px;
}

#heads_footer{
    border-top: 1px solid var(--colors-border-border-secondary, #EAECF0);
}

#get_in_touch_mobile_btn{
    border: 1px solid #91CCED !important;
    width: max-content !important;
    padding: 12px;
    border-radius: 36px;
    border-radius: 36px;
    margin-top: 14px;
    margin-bottom: 34px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
}

#navbar_toggler_btn{
    background: transparent !important;
    border: none !important;
    margin-right: 12px;
    display: none !important;
    pointer-events: none !important;
}

#header_section .navbar_list {
    gap: 40px !important;
}

@media screen and (max-width: 575px) {
    #header_section .navbar_collapsed {
        margin-top: 3% !important;
    }
    #header_section .collapse.show {
        z-index: 2 !important;
    }
    #header_container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    #header_section .nav_container_div {
        width: 100%; 
        padding: 16px 0px !important;
        border-radius: 0px;
    }
    #header_section .nav_action_buttons{
        text-align: left !important;
        border: none !important;
        background: none !important;
        justify-content: flex-start !important;
    }
    #header_section .navbar_brand{
        padding-left: 16px !important;
    }
    #header_section .navbar-toggler{
        margin-right: 16px !important;
    }
    #header_section .mobile_head_option{
        font: 500 16px/24px "Poppins" !important;
    }
    #heads_footer{
        padding: 24px 16px;
    }
    #navbar_toggler_btn{
        display: block !important;
        pointer-events: all !important; 
    }
}

@media screen and (max-width: 620px) {
    #header_section .navbar_brand {
        margin-left: 0 !important;
        margin-right: 5px !important;
    }

    #header_section .nav_action_buttons {
        margin-right: 10px !important;
        margin-top: 10px !important;
    }

    #header_section .navbar-toggler {
        justify-content: flex-end !important;
        margin-right: 0;
    }

    #header_section .navbar_list {
        width: 100% !important;
        justify-content: end;
    }
}

@media screen and (max-width: 767.5px) {
    #header_section .navbar_brand {
        margin-right: 49px !important;
        margin-left: 0px !important;
    }
    #header_section .nav_container_div {
        width: 100%; 
        padding: 16px 0px !important;
        border-radius: 0px;
    }
    #header_section .collapse.show {
        z-index: 2 !important;
    }
    #navbar_toggler_btn{
        display: block !important;
        pointer-events: all !important; 
    }
    #header_section .nav_action_buttons{
        text-align: left !important;
        border: none !important;
        background: none !important;
        justify-content: flex-start !important;
        padding: 10px 16px;
    }
    #header_container {
        height: auto !important;
        background: radial-gradient(59.12% 59.12% at 50% 50%, #27214B 0%, #170C24 100%);
    }
}

@media screen and (max-width: 995px) {
    #header_section .navbar_list {
        gap: 10px !important;
    }
}

@media screen and (max-width: 370px) {
    #header_container {
        width: 22.5em;
    }    
}