#comparisonmatrix {
    background: radial-gradient(46.59% 46.59% at 50% 63.22%, #27214B 0%, #170C24 100%);
    padding: 32px, 0px, 96px, 0px;
}

#comparision_head {
    font: 700 48px/57.6px "Poppins";
    color: #FFF;
    padding-top: 24px;
    padding-bottom: 34px;
}

.safe_text {
    background: #DB2349;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.aurm_table_logo {
    width: 120px;
    height: 43px;
}

.matrix_head {
    font: 600 24px/36px "Poppins" !important;
    color: #FFF !important;
    text-align: center !important;
    vertical-align: middle !important;
    padding: 32px 20px !important;
}


.custom-table {
    background: linear-gradient(180deg, #1E1637 -8.36%, #1C1230 145.07%);
    border-radius: 16px;
    border: 2px solid rgb(57, 50, 100) !important;
    overflow: hidden;
    border-collapse: separate !important;

}

.custom-table th,
.custom-table td {
    border: 1px solid rgb(57, 50, 100);
    background: transparent;
}

.matrix_sub_head {
    margin-top: 40px !important;
    font: 600 19px/28.5px "Poppins" !important;
    color: #FFF !important;
}

.matrix_sub_head_options {
    font: 400 20px/28px "Poppins" !important;
    color: #FFF !important;
    padding-left: 49px !important;
    padding-top: 30px;
}

.matrix_th {
    padding-top: 47px !important;
    padding-left: 24px !important;
    padding-right: 24px !important
}

.matrix_sub_head img {
    margin-right: 10px;
    padding-bottom: 3px;
}

.th_matrix_option {
    text-align: center;
}

.logo_table_div_color {
    background-color: #2D2153 !important;
}

.tr_last_column {
    padding-bottom: 40px !important;
}

@media screen and (max-width: 991px) {
    .matrix_head {
        font: 600 20px/30px "Poppins" !important;
    }

    .matrix_sub_head {
        font-size: 17px !important;
    }

    .matrix_sub_head_options {
        font-size: 16px !important;
        line-height: 30px !important;
    }
}

.bdr-y-n {
    border-top: none !important;
    border-bottom: none !important;
}

.bdr-btm-n {
    border-bottom: none !important;
}

.bdr-tp-n {
    border-top: none !important;
}

.br-rght-n {
    border-right: none !important;
}

.br-lft-n {
    border-left: none !important;
}

td {
    vertical-align: middle !important;
}

@media screen and (max-width: 450px) {
    #comparision_head {
        font-size: 24px !important;
    }

    .aurm_table_logo {
        width: 56px !important;
        height: 20px !important;
    }

    .matrix_head {
        font-size: 14px !important;
        line-height: 21px !important;
    }

    .matrix_sub_head,
    .matrix_sub_head_options {
        font-size: 14px !important;
        line-height: 21px !important;
    }

    .th_matrix_option img {
        height: 24px !important;
    }

    .matrix_star_img {
        display: none !important;
    }

    .matrix_sub_head_options {
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 15px !important;

    }

    .matrix_th {
        padding: 15px !important;
        padding-top: 20px !important;
        padding-bottom: 5px !important;
    }

    #comparision_matrix_div {
        width: 98%;
    }

    .tr_last_column {
        padding-bottom: 20px !important;
    }
}

@media screen and (max-width: 430px) {

    .matrix_sub_head,
    .matrix_sub_head_options {
        font-size: 13px !important;
        line-height: 21px !important;
    }

    .th_matrix_option img {
        height: 20px !important;
    }

    .matrix_head {
        font-size: 13px !important;
        padding: 22px 10px !important;
    }
}