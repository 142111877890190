/* terms and conditions styles */
#terms_header #header_container {
    position: revert !important;
    z-index: revert !important;
}

#terms_header .nav_container_div {
    z-index: 2 !important;
}

#terms_section {
    background: radial-gradient(59.12% 59.12% at 50% 50%, #27214B 0%, #170C24 100%) !important;
}

#terms_container {
    padding: 96px 0px 50px 0px !important;
}

#terms_container_intro {
    padding-bottom: 0px !important;
}

#terms_section .terms_published {
    font: 600 1rem/24px "Poppins";
    letter-spacing: 0em;
    text-align: center;
    color: rgba(207, 30, 77, 1);
    margin-bottom: 12px;
}

#terms_head {
    font: 700 3rem/57.6px "Poppins";
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 24px;
}

#terms_intro {
    font: 400 1.25rem/30px "Poppins";
    text-align: center;
    color: rgba(95, 136, 159, 1);
}

.terms_section_sub_heads {
    font: 700 1.75rem/39.2px "Poppins";
    letter-spacing: 0em;
    color: var(--pure-white-color);
    padding-bottom: 20px !important;
}

.terms_text {
    margin-bottom: 1.5rem;
}

.terms_list_text,
.terms_text {
    font: 300 1.125rem/27px "Poppins" !important;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1) !important;
    opacity: 0.6;
}

@media screen and (min-width: 767px) and (max-width: 1199px) {
    .terms_div {
        width: 80% !important;
    }
}

@media screen and (min-width: 750px) and (max-width: 767px) {
    .terms_div {
        width: 100% !important;
    }
}

@media screen and (max-width: 550px) {
    .terms_section_sub_heads {
        font-size: 20px !important;
    }

    .terms_list_text,
    .terms_text {
        font-size: 16px !important;
    }

    #terms_head {
        font-size: 30px !important;
    }

    #terms_container {
        padding: 30px 0px 0px 0px !important;
    }
}