/* Features section styles */
#feature_section {
    background: var(--features-radial-background);
}

#feature_section .features_div {
    padding-bottom: 80px !important;
    max-height: auto !important;
    padding-left: 32px !important;
    padding-right: 32px !important;
}

#feature_section .features_div .img {
    height: 138px;
    top: 0;
    position: absolute;
    width: 149px;
}

#feature_section .feature_header {
    font: 700 3rem/120% "Poppins";
    color: var(--pure-white-color);
    margin-top: -1% !important;
}

#feature_section #feature_header_sub_text {
    color: var(--light-red-text-color);
}

#feature_section .feature_sub_text {
    width: 71%;
    font: 400 1.5rem/140% "Poppins" !important;
    color: var(--light-sky-blue);
    padding-top: 32px;
}

#feature_section .feature_card {
    max-width: 24rem !important;
    min-width: 23rem !important;
    margin: 1em;
    padding: 1.8rem;
    border-radius: var(8px, 0.5rem);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(57.79, 50.54, 100.07), rgb(20.15, 11.49, 33.14)) 1;
    border-radius: 8px !important;
    background: linear-gradient(180deg, #1E1637 -8.36%, #1C1230 145.07%);
    position: relative;
}

#feature_section .feature_card::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: calc(50% - 15%);
    width: 30%;
    height: 30px;
    background-image: url('../images/glo.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#feature_section .card_header {
    text-align: center;
    font: 700 1.4rem/140% "Poppins";
    color: var(--pure-white-color);
}

#feature_section .card_sub_text {
    font: 400 1rem/150% "Poppins" !important;
    color: var(--light-sky-blue);
    padding-top: 8px;
}

.cards_row {
    margin-top: -5em;
}

#feature_top_dots_img {
    margin-left: 1em;
}

.dots_image {
    margin-right: -2%;
}

@media screen and (max-width: 1399.5px) {
    #feature_section .feature_header {
        font-size: 2.6rem;
    }

    #feature_section .feature_sub_text {
        font-size: 1.3rem !important;
    }

    #feature_section .feature_card {
        max-width: 21rem !important;
        min-width: 21rem !important;
        margin: 0 !important;
    }

    .cards_row {
        gap: 2.5em !important;
    }

    #feature_section .card_header {
        font-size: 1.25rem;
    }

    .dots_image {
        margin-right: -3em;
    }
}

@media screen and (max-width: 1199.5px) {
    #feature_section .feature_header {
        font-size: 2rem;
    }

    #feature_section .feature_sub_text {
        font-size: 1rem !important;
    }

    .dots_image {
        margin-right: 3em;
    }

}

@media screen and (max-width: 992px) {
    .features_div {
        max-width: 91%;
    }

    .dots_image {
        margin-right: 0.7em;
    }
}

@media screen and (max-width: 978.5px) {
    .dots_image {
        margin-right: 0.7em;
    }
}

@media screen and (max-width: 954px) {
    .dots_image {
        margin-right: -0.75em;
    }
}

@media screen and (max-width: 908.5px) {
    .dots_image {
        margin-right: -2.2em;
    }
}

@media screen and (max-width: 768px) {
    .dots_image {
        margin-right: 0%;
    }
}

@media screen and (max-width: 574px) {
    #feature_section .feature_sub_text {
        width: 80% !important;
        font-size: 0.8rem !important;
    }

    #feature_section .features_div {
        max-width: 100% !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    #feature_section .card_header {
        font-size: 1.15rem;
    }

    #feature_section .feature_card {
        padding: 1rem !important;
        max-width: 18rem !important;
        min-width: 18rem !important;
    }
    #feature_section .feature_header {
        font-size: 1.5rem;
        margin-top: 15px !important;
    }
    .card_text{
        margin-top: -20px; 
    }
    
    #feature_section .feature_sub_text{
        padding-top: 12px !important;
    }
}

@media screen and (max-width: 430px) {
    #feature_dots_img_tag {
        width: 84.999 !important;
        height: 84.999px !important;
    }

    #feature_top_dots_img {
        margin-left: 0 !important;
        padding-left: 0px !important;
    }

    #feature_section .feature_header {
        margin-top: -33px !important;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;
    }

    #feature_section .feature_sub_text {
        padding-top: 32px !important;
        font-size: 1.125rem !important;
        width: 100% !important;
    }

    #feature_section #card_back_dots {
        display: none;
    }

    .cards_row {
        margin-top: 0 !important;
        row-gap: 4em !important;
    }

    #feature_section .feature_card {
        padding: 32px !important;
    }

    #feature_section .feature_card {
        max-width: 94% !important;
        min-width: 94% !important;
    }

    #feature_section .features_know_more_btn {
        margin-top: 2.5rem !important;
        font-size: 14px !important;
        width: 10.75rem !important;
        height: 2.5rem;
    }

    #feature_section .card_header{
        font-size: 1.5em;
    }
    #feature_section .card_sub_text {
        font-size: 18px !important;
    }
    #feature_section .features_div {
        padding-left: 16px !important;
        padding-right: 16px !important;
        overflow: hidden;
    }
}

@media screen and (max-width: 428px) {
    #feature_section .card_header{
        font-size: 1.4em !important;
    }
}

@media screen and (max-width: 405px) {
    #feature_section .card_header{
        font-size: 1.2em !important;
    }
}