#individual_blogs_page_div {
    background: radial-gradient(59.12% 59.12% at 50% 50%, #27214B 0%, #170C24 100%) !important;
}

#blogs_list_section{
    background: radial-gradient(46.59% 46.59% at 50% 63.22%, #27214B 0%, #170C24 100%) !important;
}

#individual_blogs_page_div #blogs_list_section {
    padding-top: 32px !important;
    background: radial-gradient(46.59% 46.59% at 50% 63.22%, #27214B 0%, #170C24 100%) !important;
    padding-bottom: 0px !important;
}

#individual_blogs_page_div #blogs_list_section #blogs_list_container {
    margin-top: 56px !important;
}

#blogs_page_div{
    background: radial-gradient(46.59% 46.59% at 50% 63.22%, #27214B 0%, #170C24 100%);
}