/* Partner section styles */
#partner_container {
    position: relative;
    padding: 0px !important;
    background: radial-gradient(47.4% 47.4% at 50% 51.71%,
            #27214b 0%,
            #170c24 100%);
}

#partner_background {
    margin-top: -2px !important;
    height: 45em !important;
}
/* added */
#partner_cards_div .partner_cards img {
    height: 240px;
}

#partner_content {
    position: absolute;
    top: 56% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    background-color: transparent;
}

#header_part {
    max-width: 768px;
    min-width: auto;
}

#partner_heading {
    font: 700 3rem/57.6px "Poppins";
    color: var(--pure-white-color);
    margin-bottom: 20px;
}

#partner_sub_text {
    font: 400 1.5rem/140% "Poppins";
    color: var(--light-sky-blue);
}

.card_text {
    font: 500 1.25rem/36px "Poppins";
    color: var(--light-sky-blue);
    margin-top: 1.5rem ;
}

.partner_cards {
    min-width: 17em !important;
    max-width: 17em !important;
}

#partner_cards_div {
    padding-top: 50px !important;
    padding-bottom: 96px !important;
}

@media screen and (max-width: 1199px) {
    .partner_cards {
        min-width: 14em !important;
        max-width: 14em !important;
    }
    .card_text{
        font-size: 1.08rem;
    }
    /* addednew */
    #partner_cards_div .partner_cards img {
        height: auto !important;
    }
}

@media screen and (max-width: 1000px) {
    .partner_cards {
        min-width: 20em !important;
        max-width: 20em !important;
        margin-bottom: 1.5rem !important;
    }

    #partner_background {
        height: 57em !important;
    }

    #partner_content {
        top: 58% !important;
    }
    .card_text {
        font-size: 1.25rem;
        margin-top: 0.6rem !important ;
    }
    #partner_cards_div .partner_cards img {
        width: 200px;
        height: 200px;
      }
}

@media screen and (max-width: 767px) {
    .partner_cards {
        min-width: 16em !important;
        max-width: 16em !important;
    }

    #partner_content {
        top: 57% !important;
    }
}

@media screen and (max-width: 560px) {
    #partner_sub_text {
        font-size: 1rem;
    }

    #partner_background {
        height: 44em !important;
    }

    #partner_content {
        top: 54% !important;
    }

    #partner_heading {
        font-size: 2rem;
    }
    #partner_cards_div {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
    }
    #partner_heading {
        margin-bottom: 0px !important;
    }
    #partner_cards_div .partner_cards img {
        width: 170px;
        height: 170px;
      }
}

@media screen and (max-width: 431px) {
    #partner_heading {
        font-size: 1.2rem;
    }
    
}

@media screen and (max-width: 538px) {
    .partner_cards {
        min-width: 14em !important;
        max-width: 14em !important;
        padding: 1px !important;
    }

    .card_text {
        font-size: 0.8rem;
    }

}

@media screen and (max-width: 471.5px) {
    .partner_cards {
        min-width: 12em !important;
        max-width: 12em !important;
    }
    #partner_content {
        top: 54% !important;
    }
    #partner_cards_div .partner_cards img {
        margin-top: 1rem !important;
      }
}

@media screen and (max-width:430px) {
   #partner_cards_div .row{
    justify-content: space-around !important;
   }
   #partner_background {
    height: 47.5em !important;
    }
    #partner_cards_div .partner_cards img {
        width: 180px !important;
        height: 180px !important;
    }
    #partner_cards_div{
        padding-left: 3px !important;
        padding-right: 3px !important;
    }
    #partner_heading {
        font: 700 1.5rem/28.8px "Poppins";
    }
    #partner_sub_text {
        font: 400 1rem/22.4px "Poppins";
        margin-top: 1rem !important;
    }
}

@media screen and (max-width:420px) {
    #mobile_partner_bg_img{
        display: block !important;
    }
    #partner_bg_img{
        display: none !important;
    }
}

@media screen and (max-width: 410px) {
    .partner_cards {
        min-width: 10em !important;
        max-width: 10em !important;
    }
    #partner_cards_div .partner_cards img {
        width: 160px !important;
        height: 160px !important;
      }
}


@media (min-width: 2550px) {
    #partner_content {
        top: 50% !important;
    }
}

.partner_bg_img {
    height: inherit !important;
}