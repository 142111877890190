/* Team card styles */
.team_card_desg_color {
    background: #DB2349;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.team-profile-images{
    margin: auto;
}

#teamcard_container {
    padding: 0px;
    background: transparent !important;
    border: none !important;
}

#team_card_title {
    font: 600 1.1rem/32px "poppins";
    color: var(--pure-white-color);
}

#team_card_head {
    padding-top: 20px;
    justify-content: center;
}
.team_card_content{
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

#team_card_designation {
    font: 700 1rem/24px "Poppins";
}

.team-profile-images{
    padding: 1px 1rem 0rem 1rem;
}

#team_card_description {
    background:#B8D5E0;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: 400 1rem/24px "Poppins";
    margin-top: -10px;
}

.teamcard_linked_in_image{
    margin-left: -45%;
    display: none !important;
}

.team_card_img{
    width: 200px !important;
    height: 198px !important;
}

/* .linked_in_cards{
    margin-left: 1.9rem;
} */
@media screen and (max-width: 1199px) {
    #team_card_title {
        font-size: 0.8rem !important;
    }
    #team_card_designation {
        font: 700 0.8rem/24px "Poppins";
    }
    #team_card_description {
        font: 400 0.8rem/24px "Poppins";
    }
}

@media screen and (max-width: 1003px) {
    #team_card_title {
        font-size: 0.78rem !important;
    }
    #team_card_designation {
        font: 700 0.7rem/24px "Poppins";
    }
    #team_card_description {
        font: 400 0.7rem/24px "Poppins";
    }
    
}

@media screen and (max-width: 991px) {
    #team_card_title {
        font-size: 1.384rem !important;
    }
    #team_card_designation {
        font: 700 1rem/24px "Poppins";
    }
    #team_card_description {
        font: 400 1rem/24px "Poppins";
    }
}

@media (min-width: 537px) and (max-width: 991px) {
    #teamcard_container{
        margin-bottom: 10% !important;
    }
}

@media screen and (max-width: 720px) {
    #team_card_title {
        font-size: 1.384rem !important;
    }
}

@media screen and (max-width: 586px) {
    #team_card_title {
        font-size: 1.2rem !important;
    }
    #team_card_designation {
        font: 700 0.9rem/24px "Poppins";
    }
    #team_card_description {
        font: 400 0.9rem/24px "Poppins";
    }
}

@media screen and (max-width: 430px) {
    #team_card_title {
        font-size: 1.2rem !important;
        padding-right: 8px !important;
    }
    .teamcard_linked_in_image{
        margin-left: 0%;
    }
    #middle_dots {
        right: 0.8em;
    }
    .team_cards:last-child {
        padding-bottom: 0px !important;
    }
}

@media screen and (max-width: 1399px) {
    #team_card_designation{
        font-size: 0.82rem !important;
    }
    #team_card_title{
        font-size: 1.05rem !important;
    }
    
}
