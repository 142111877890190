/* Team section styles */

#team_section{
    background: radial-gradient(46.59% 46.59% at 50% 63.22%, #27214B 0%, #170C24 100%);
}
.blue_text_color {
    background:#B8D5E0;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#team_container_div {
    padding: 96px 0px !important;
    padding-bottom: 0px !important;
    padding-top: 40px !important;
}

#team_section_dots {
    margin-left: -30px;
}

#team_container {
    position: relative !important;
    margin-top: -13em !important;
    width: 99% !important;
    padding-top: 64px !important;
    padding-bottom: 64px !important;
    border-radius: 15px;
    border-top: 2px solid #3A3364;
    border-bottom: rgb(17, 11, 29) solid 2px !important;
    border-left:#170C24;
    border-right: #170C24;
    background: linear-gradient(180deg, #1E1637 -8.36%, #1C1230 145.07%) !important;
}

#team_container{
    padding-left: 0px;
    padding-right: 0px;
}

.team_section_dots_div {
    position: relative;
    margin-right: -2.1em !important;
    margin-top: -91px;
}


#middle_dots {
    position: relative;
    top: 43em !important;
    right: -2.1em;
}

#team_section_title {
    font: 700 48px/57.6px "Poppins";
    color: #FFF;
}

.teams-safe-hands{
    padding-left: 32px !important;
    padding-right: 32px !important;
}

#safe_text {
    background:#DB2349;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#team_section_subtitle {
    font: 400 24px/33.6px "Poppins";
    max-width: 900px !important;
    padding-top: 32px;
    padding-bottom: 96px;
}


.team_cards{
    max-width: 360px !important;
}


@media screen and (max-width: 1003px) {
    #team_container{
        max-width: 95% !important;
    }
    #team_section_dots {
        margin-left: -22px;
    }
    #middle_dots {
        top: 60em !important;
        right: -1.5em;
    }
    .team_section_dots_div {
        right: 0.5em !important;
    }
    #team_section_title {
        font: 700 2.5rem/120% "Poppins";
    }
    #team_section_subtitle {
        font: 400 1.5rem/140% "Poppins";
    }
}
@media (min-width: 991px) and (max-width: 1199px) {
    .team_cards {
        max-width: 360px !important;
        min-width: 20em !important;
    }
    .team_card_content_row{
        row-gap:2em !important;
    }
}
@media screen and (max-width: 980px) {

    #middle_dots {
        top: 80em !important;
    }
    #team_section_title {
        font: 700 2.3rem/120% "Poppins";
    }
    #team_section_subtitle {
        font: 400 1.3rem/140% "Poppins";
    }

}

@media screen and (max-width: 586px) {
    #team_container{
        max-width: 85% !important;
    }
    #team_section_dots {
        margin-left: -5px;
    }
    #middle_dots {
        right: -0.2em;
    }
    .team_section_dots_div {
        right: 2.2em !important;
    }
    #team_section_title {
        font: 700 2rem/120% "Poppins";
    }
    #team_section_subtitle {
        font: 400 1.1rem/140% "Poppins";
    }
}

@media screen and (max-width: 536px) {
    #team_container{
        max-width: 100% !important;
    }
    .team_cards{
        min-width: 320px !important;
        max-width: 320px !important;    
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 36px;
    }
    #team_section_title {
        font: 700 2rem/120% "Poppins";
    }
    #team_section_subtitle {
        font: 400 1.1rem/140% "Poppins";
    }
    #team_container {
        padding: 35px !important;
    }
}

@media screen and (max-width: 431px) {
    
    #team_section_title {
        font-size: 1.2rem !important;
    }
}

@media screen and (max-width: 390px) {
    #team_container {
        padding-top: 30px !important;
        padding-left: 20px !important;
        padding-right:20px !important;
        padding-bottom: 25px !important;
    }
}

@media screen and (max-width: 430px){
    #team_section_dots {
        margin-left: 13px !important;
    }
    #team_container_div{
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
    #team_section_title {
        font-size: 1.5rem !important;
    }
    #team_section_subtitle {
        font-size: 1rem !important;
        padding-bottom: 55px !important;
    }
    .teams-safe-hands {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    #team_container{
        padding-left: 27px !important;
        padding-right: 27px !important;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
}

