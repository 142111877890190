/* Landing section styles */
#landing_section {
  position: relative;
  background-color: #241f46 !important;
}

#video_container {
  overflow: hidden !important;
}

#landing_section video {
  width: 100%;
  object-fit: cover;
}

#landing_section .landing_overlay_text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font: 700 4rem/120% "Poppins";
  color: var(--pure-white-color);
  height: 217px !important;
  padding: 0px 32px 0px 32px;
}

#landing_section .landing_subtext {
  color: #FFF !important;
  font: 400 1.75rem/140% "Poppins" !important;
  padding-top: 24px;
}

.rounded_pink_btn {
  position: relative;
  width: auto !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1.875rem;
  background: linear-gradient(0deg, #9D0154 -13.28%, #EA1855 110.94%);
  color: rgba(255, 255, 255, 1);
  font: 600 1.75rem/140% "Poppins";
  border: none !important;
  gap: 1rem;
}

.touch_btn_div {
  top: 96.9%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#video_bottom_img_div {
  position: absolute;
  top: 95.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: transparent !important;
}

#video_mask_img {
  height: 80px;
}

#tinted_overlay {
  background: rgba(43, 43, 43, 0.40);
  height: 55.63em;
  margin-top: -56em !important;
  position: relative;
}

@media (2050.5px > width >1500px) {
  #tinted_overlay {
    height: 77.3em !important;
    margin-top: -77.5em !important;
  }

  .rounded_pink_btn {
    border-radius: 2.45rem;
    font-size: 2.35rem;
  }

  #video_bottom_img_div {
    top: 96%;
  }

  #video_mask_img {
    height: 90px;
  }
}

@media screen and (max-width: 1399.5px) {
  #landing_section .landing_overlay_text {
    font-size: 3.8rem !important;
    top: 57%;

  }

  #video_bottom_img_div {
    top: 95%;
  }
}

@media screen and (max-width: 1199.5px) {
  #landing_section .landing_overlay_text {
    font-size: 3rem !important;
    top: 63%;
  }

  #video_bottom_img_div {
    top: 94.5%;
  }
}

@media (2551px > width >2050px) {
  .rounded_pink_btn {
    border-radius: 3.45rem;
    font-size: 3.35rem;
  }

  #video_bottom_img_div {
    top: 96%;
  }

  #video_mask_img {
    height: 131px;
  }

  #tinted_overlay {
    height: 93em !important;
    margin-top: -93.1em !important;
  }
}

@media screen and (max-width: 991px) {
  #landing_section .landing_overlay_text {
    font-size: 2.2rem !important;
    top: 75%;

  }

  #landing_section .landing_subtext {
    color: #FFF !important;
    font-size: 1.3rem !important;
  }

  #video_bottom_img_div {
    top: 93%;
  }
}
@media screen and (max-width: 900px) {

  #video_bottom_img_div {
    top: 92%;
  }
}

@media screen and (max-width: 767.5px) {
  #landing_section .landing_overlay_text {
    font-size: 1.5rem !important;
    top: 82%;

  }

  #landing_section .landing_subtext {
    font-size: 1rem !important;
  }

  #video_bottom_img_div {
    top: 91%;
  }
  
}

@media screen and (max-width: 750px) {

  .rounded_pink_btn {
    font-size: 1.2rem !important;
  }

  #video_mask_img {
    height: 72px;
  }

  #video_bottom_img_div {
    top: 91.5%;
  }

  #tinted_overlay {
    height: 50em !important;
    margin-top: -50.29em !important;
  }
}

@media screen and (max-width: 690px) {
  #video_bottom_img_div {
    top: 91%;
  }

  #tinted_overlay {
    height: 38em !important;
    margin-top: -38.29em !important;
  }

  .touch_btn_div {
    top: 95.9%;
  }
}

@media screen and (max-width: 669px) {
  #video_bottom_img_div {
    top: 90%;
  }
}

@media screen and (max-width: 544px) {
  .rounded_pink_btn {
    font-size: 1rem !important;
  }

  #video_bottom_img_div {
    top: 90%;
  }

  #video_mask_img {
    height: 60px;
  }

  #tinted_overlay {
    height: 510.5px !important;
    margin-top: -32.31em !important;
    overflow: hidden !important;
  }

  #landing_section .landing_overlay_text {
    top: 85%;
  }

  #landing_section .landing_subtext {
    padding-top: 8px;
  }
}

@media screen and (max-width: 485px) {
  #landing_section .landing_overlay_text {
    font-size: 1.2rem !important;
    top: 88%;

  }
  #landing_section .landing_subtext {
    font-size: 1.1rem !important;
  }
  #video_bottom_img_div {
    top: 89%;
  }
}

@media screen and (max-width: 430px) {
  #lading_Section_for_mobile{
    padding-top: 4.2rem !important;
  }
  #video_mask_img {
    height: 37px !important;
    width: 14rem !important;
  }
  #video_bottom_img_div {
    top: 93.85% !important;
    width: auto !important;
  }
  .rounded_pink_btn {
    position: relative;
    width: auto !important;
    height: 40px !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 1.9531rem;
    font: 600 0.94rem/21px "Poppins";
    border: none !important;
    gap:5px;
  }
  .touch_btn_div {
    top: 99.9%;
  }
  #landing_section .landing_overlay_text {
    font: 700 1.25rem/24px "Poppins";
    top: 86% !important;
  }
  #landing_section .landing_subtext {
    font: 500 0.9rem/19.6px "Poppins" !important;
  }
  #tinted_overlay {
    height: 510.8px !important;
  }
}

@media screen and (max-width: 406px) {
  #tinted_overlay {
    height: 510px !important;
  }
}

@media screen and (max-width: 400px) {
  #landing_section .landing_overlay_text {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 391.5px) {
  #landing_section .landing_overlay_text {
    font-size: 0.9rem !important;
    top: 88% !important;
  }
  #landing_section .landing_subtext {
    font-size: 0.8rem !important;
  } 
}

@media (min-width: 2551px) {
  .rounded_pink_btn {
    border-radius: 3.45rem;
    font-size: 3.35rem;
  }

  #video_bottom_img_div {
    top: 96%;
  }

  #video_mask_img {
    height: 130px !important;
  }

  #tinted_overlay {
    height: 100em !important;
    margin-top: -100em !important;
  }
}
