/* reach-us section styles. */
#reach_us_section {
    background: radial-gradient(46.59% 46.59% at 50% 63.22%, #27214B 0%, #170C24 100%);
    padding: 96px 0px 96px 0px !important
}

.reachus_primary_text {
    font: 700 3rem/57.6px "Poppins";
    letter-spacing: 0;
}

.white_text {
    color: var(--pure-white-color);
}

.whatsapp_text_color {
    color: var(--light-red-text-color);
}

.reachus_secondary_text {
    font: 500 1.5rem/33.6px "Poppins";
    letter-spacing: 0;
    color: var(--light-sky-blue);
    padding-top: 20px;
}

.reach_us_whatsapp_img {
    height: 60px;
    width: 60px;
    margin-left: 12px;
    padding-top: 8px;
}

@media screen and (max-width: 991px) {
    .reachus_primary_text {
        font-size: 36px !important;
    }

    .reach_us_whatsapp_img {
        width: 56px !important;
        height: 56px !important;
        padding-top: 0px;
    }

    .reachus_secondary_text {
        margin-top: -9px !important;
        font-size: 18px !important;
    }
}

@media screen and (max-width: 767px) {
    .reachus_primary_text {
        font-size: 26px !important;
    }

    .reach_us_whatsapp_img {
        width: 48px !important;
        height: 48px !important;
    }

    .reachus_secondary_text {
        font-size: 16px !important;
        margin-top: -14px !important;
    }
}

@media screen and (max-width: 541px) {
    .reachus_primary_text {
        font-size: 22px !important;
    }

    .reach_us_whatsapp_img {
        padding-top: 0.5em !important;
        width: 45px !important;
        height: 45px !important;
    }

    .reachus_secondary_text {
        font-size: 12px !important;
        padding-top: 0px !important;
    }

    #reach_us_section {
        padding: 55px 0px 55px 0px !important;
    }
}

@media screen and (max-width:452px) {
    .reachus_primary_text {
        font-size: 16px !important;
    }
    .reach_us_whatsapp_img {
        margin-left: 6px;
    }
}

@media screen and (max-width:371.5px) {
    .reachus_primary_text {
        font-size: 15.1px !important;
    }
}