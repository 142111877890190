#faqs_main_section{
  background: var(--faq-bg-color, #170C24);
  padding: 6rem 11.5625rem 0rem 11.5625rem;
  gap: 0.625rem;
  color: var(--color-white, #fff);
}

.faq-item{
  margin: 2.5rem 0 2.5rem 0;
}

.faq-answer li::before {
  color: #91CCED !important;
  display: inline-block !important;
  width: 1em !important;
  margin-left: -1em !important;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.8rem;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 1rem;

}

.faq_arrow {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease-in-out;
}

.faq_arrow.down {
  transform: rotate(0deg);
}

.faq_arrow.up {
  transform: rotate(180deg);
}

.faqs_heading{
  font-family: Poppins;
  font-size: 3rem;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;

}

.faq-answer{
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #91CCED;
}

.faq_sections_heading{
font-family: Poppins;
font-size: 2rem;
font-weight: 700;
line-height: 38.4px;
letter-spacing: 0em;
background: #91CCED;
-webkit-background-clip: text;
background-clip: text;
color: transparent;
}

.faq_sections_div{
margin-bottom: 6rem !important;
}

.faq_cancel_retrieval_div{
  margin-bottom: 0 !important ;
}

.faq_security_section{
margin-top: 2.5rem !important;
}

@media screen and (max-width: 991px) {
  .faqs_heading{
      font-size: 2.25rem;
      line-height: 2.8rem;
    }
    .faq-question {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
    .faq-answer{
      font-size: 0.8rem;
      line-height: 1.05rem;
    }
    #faqs_main_section{
      padding: 6rem 8.5625rem 0rem 8.5625rem;
  }
  .faq_sections_heading{
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 767px) {
  .faqs_heading{
      font-size: 1.625rem;
      line-height: 2.12rem;
    }
    .faq-question {
      font-size: 1.1rem;
      line-height: 1.4rem;
    }
    .faq-answer{
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
    #faqs_main_section{
      padding: 6rem 5.5625rem 0rem 5.5625rem;
  }
.faq_sections_heading{
  font-size: 1.5rem !important;
}
}

@media screen and (max-width: 575px) {
.faq_sections_div{
  margin-bottom: 2rem !important;
}
.faq_sections_heading{
  font-size: 1.4rem !important;
}
}

@media screen and (max-width: 541px) {
  .faqs_heading{
      font-size: 1.5em;
      line-height: 2.1rem;
    }
    .faq-question {
      font-size: 1.1em;
      line-height: 1.3rem;
    }
    .faq-answer{
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
    #faqs_main_section{
      padding: 6rem 4.5625rem 0rem 4.5625rem;
  }
  .faq_sections_heading{
    font-size: 1.3rem !important;
  }
}

@media screen and (max-width:482px) {
.faq_sections_div{
  margin-bottom: 1.5rem !important;
}
}

@media screen and (max-width:452px) {
  .faqs_heading{
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
    .faq-question {
      font-size: 0.8em;
      line-height: 1.2rem;
    }
    .faq-answer{
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
    #faqs_main_section{
      padding: 6rem 3.5625rem 0rem 3.5625rem;
  }
  .faq_sections_heading{
    font-size: 1rem !important;
    line-height: 2rem;
  }
}
@media screen and (max-width:430px) {
  .faqs_heading{
      font-size: 1.5rem;
      line-height: 28.8px;
    }
  .faq_sections_heading{
      font-size: 20px !important;
      line-height: 24px;
    }
    .faq-question {
      font-size: 16px;
      line-height: 19.2px;
    }
    .faq-answer{
      font-size: 12px;
      line-height: 18px;
    }
    .faq_arrow {
      width: 15px;
      height: 7.5px;
      transition: transform 0.3s ease-in-out;
    }
    #faqs_main_section {
      padding: 5rem 4px 0rem 4px;
  }
  .faq_sections_div{
    margin-bottom: 1rem !important;
  }
  .faq_security_section{
    margin-top: 36px !important;
  }
  #footer_section{
    margin-top: 46px !important;
  }
}

@media screen and (max-width:401px) {
  .faqs_heading{
      font-size: 1.3rem;
      line-height: 25px;
    }
  .faq_sections_heading{
      font-size: 18px !important;
      line-height: 22px;
    }
    .faq-question {
      font-size: 14.5px;
      line-height: 18px;
    }
    .faq-answer{
      font-size: 11px;
      line-height: 16px;
    }
    .faq_arrow {
      width: 13.9px;
      height: 7px;
      transition: transform 0.3s ease-in-out;
    }
    #faqs_main_section {
      padding: 4.5rem 4px 0rem 4px;
  }
  .faq_sections_div{
    margin-bottom: 0.9rem !important;
  }
  .faq_security_section{
    margin-top: 33px !important;
  }
}

#faq_links{
  color: #EA1855;
  text-decoration: underline !important;;
}