/* book your aurm safe section styles. */
#book_your_aurm_safe_root {
    background: radial-gradient(37.98% 37.98% at 50% 54.62%, #27214B 0%, #170C24 100%);
    padding: 48px 24px 0px 24px !important;
 
    height: 100vh;
}

#book_your_aurm_safe_section {
    padding: 48px 0px 24px 0px !important;
}

.book_your_aurm_safe_primary_text {
    font: 700 3rem/57.6px "Poppins";
    letter-spacing: 0;
}

.white_text {
    color: var(--pure-white-color);
}

.book_your_aurm_safe_secondary_text {
    color: var(--pure-white-color) !important;
    font: 700 48px "Poppins";
    letter-spacing: 0;
    padding-top: 20px;
    
}

.book_your_aurm_safe_img_div{
    gap : 1.1rem !important;
    flex-wrap: wrap;
    padding: 2rem;
}

.book_your_aurm_safe_playstore_imgs{
    width: 11rem !important;
    height: 4rem !important;   
}

@media screen and (max-width: 991px) {
    .book_your_aurm_safe_primary_text {
        font-size: 2.25rem !important;
    }

    .book_your_aurm_safe_playstore_imgs {
        width: 11rem !important;
        height: 4rem !important;
    }

    .book_your_aurm_safe_secondary_text {
        margin-top: -9px !important;
        font-size: 18px !important;
    }
}

@media screen and (max-width: 767px) {
    .book_your_aurm_safe_primary_text {
        font-size: 1.625rem !important;
    }
    .book_your_aurm_safe_playstore_imgs {
        width: 11rem !important;
        height: 4rem !important;
    }
    .book_your_aurm_safe_secondary_text {
        font-size: 16px !important;
        margin-top: -16px !important;
    }
}

@media screen and (max-width: 541px) {
    .book_your_aurm_safe_primary_text {
        font-size: 1.5rem !important;
        margin-top: 1rem !important;
    }

    .book_your_aurm_safe_secondary_text {
        font-size: 1rem !important;
        padding-top: 7px !important;
    }
    .book_your_aurm_safe_playstore_imgs {
        width: 11rem !important;
        height: 4rem !important;
    }
    #book_your_aurm_safe_root{
        padding: 27px 16px 16px 16px !important;
    }

    #book_your_aurm_safe_section{
        padding-top: 32px!important;
    }
}

@media screen and (max-width:452px) {
    .book_your_aurm_safe_primary_text {
        font-size: 1.2rem !important;
    }
}

@media screen and (max-width:371.5px) {
    
    .book_your_aurm_safe_primary_text {
        font-size: 1rem !important;
    }
    .book_your_aurm_safe_secondary_text {
        font-size: 0.9rem !important;
        padding-top: 0px !important;
    }
}