/* Global styles */
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:400;700|Hind:400;600");

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html,
body {
  min-width: 360px;
  margin: auto;
  height: 100%;
  max-width: 2550px;
  font-family: "Poppins" !important;
}

button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}

a {
  text-decoration: none !important;
}

:root {
  --gradient-background: radial-gradient(50% 50% at 50% 50%, rgb(39.03, 33.25, 74.57) 0%, rgb(22.93, 12.41, 36.32) 100%) !important;
  --pure-white-color: rgba(255, 255, 255, 1);
  --linear-white-gradient: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
  --radial-gradient-background: radial-gradient(50% 50% at 50% 50%, rgb(167 106 106) 0%, rgb(177 61 61 / 43%) 100%) !important;
  --voilate-radial-background: radial-gradient(52.63% 52.63% at 104.53% 84.80%, #312A5E 0%, #25123E 100%);
  --features-radial-background: radial-gradient(78% 78% at 50% 0%, #27214B 0%, #170C24 100%);
  --dark-violet-bg-color:radial-gradient(59.12% 59.12% at 50% 50%, #27214B 0%, #170C24 100%) ;
  --input-background: #004870;
  --light-sky-blue :#B8D5E0;
  --light-red-text-color :#DB2349;
  --faq-bg-color: #170C24;
}

.blur-background {
  filter: blur(10px); /* Adjust the blur amount as needed */
}  

